import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 3rem 1rem;
  background-color: #d9f3ef;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
`;

const ServiceList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const ServiceItem = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ServiceImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const ServiceTitle = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: #666;
`;

const Services = () => {
  return (
    <Section id="services">
      <Title>Serviços Oferecidos</Title>
      <ServiceList>
        <ServiceItem>
          <ServiceImage src="/images/chavecodificada.jpeg" alt="Chaves Codificadas" />
          <ServiceTitle>Chaves Codificadas</ServiceTitle>
          <ServiceDescription>
            Criação e cópia de chaves codificadas para carros modernos, com segurança e precisão.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem>
          <ServiceImage src="/images/chaveeletrica.jpeg" alt="Chaves Eletrônicas" />
          <ServiceTitle>Chaves Eletrônicas</ServiceTitle>
          <ServiceDescription>
            Serviços para chaves eletrônicas, garantindo a funcionalidade e segurança do seu veículo.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem>
          <ServiceImage src="/images/chavecodificadaparamoto.jpeg" alt="Fechaduras Eletrônicas" />
          <ServiceTitle>Chave Codificada para Moto</ServiceTitle>
          <ServiceDescription>
          Perdeu a chave da sua moto? Não tem mais a chave reserva? Ou não funciona mais? Fazemos também a chave codificada para sua moto.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem>
          <ServiceImage src="/images/copia_de_chave.jpeg" alt="Cópia de Chaves de Carros" />
          <ServiceTitle>Cópia de Chaves de Carros</ServiceTitle>
          <ServiceDescription>
            Cópia de chaves para carros nacionais e importados, incluindo chaves codificadas e convencionais.
          </ServiceDescription>
        </ServiceItem>
      </ServiceList>
    </Section>
  );
};

export default Services;