import React from 'react';
import styled from 'styled-components';
import backgroundImage from '../assets/background.jpeg'; // Imagem de fundo

const Section = styled.section`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Efeito parallax */
  color: #e2e6ee;
  padding: 200px 30px;
  text-align: center;
  font-weight: bold;
  position: relative;
  overflow: hidden;

  /* Filtro preto semitransparente apenas no desktop */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3); /* Transparente por padrão */
    z-index: 1;
    
    @media (min-width: 768px) {
      background: rgba(0, 0, 0, 0.5); /* Preto semi-transparente no desktop */
    }
  }

  & > * {
    position: relative;
    z-index: 2;
  }
`;

const Title = styled.h1`
  font-size: 2.2rem; /* Tamanho padrão para mobile */

  @media (min-width: 768px) {
    font-size: 3rem; /* Tamanho maior para desktop */
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  margin: 1rem 0;
`;

const WhatsAppButton = styled.a`
  background-color: #25d366;
  color: white;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.1rem;
`;

const Presentation = () => {
  return (
    <Section id="home">
      <Title>Chaveiro Falcão</Title>
      <Description>
        Com uma sede própria consolidada há mais de 15 anos, garantimos um atendimento ágil 
        solucionando problemas com tecnologia e qualidade.<br/> Nossa equipe está sempre pronta para
        atender suas necessidades de urgência ou emergência, colocando à disposição toda a nossa
        expertise e dedicação.
      </Description>
      <WhatsAppButton href="https://wa.me/5511965070544?text=Ol%C3%A1%20Chaveiro%20Falc%C3%A3o!%20Gostaria%20de%20informações%20sobre%20chaves%20codificadas." target="_blank" rel="noopener noreferrer">
        Click aqui (11) 96507-0544
      </WhatsAppButton>
    </Section>
  );
};

export default Presentation;
