import React, { useRef } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

const Section = styled.section`
  background-color: #c9e6f3;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
  font-size: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
  }

  input,
  textarea {
    margin-bottom: 1.5rem;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
    outline: none;

    &:focus {
      border-color: #282c34;
      box-shadow: 0 0 5px rgba(40, 44, 52, 0.5);
    }
  }

  button {
    background-color: #282c34;
    color: white;
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #1f1f1f;
    }
  }
`;

const WhatsAppButton = styled.a`
  background-color: #25d366;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1da951;
  }
`;

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_a025eo5', // Substitua pelo seu ID de serviço
        'template_et2c8oa', // Substitua pelo seu ID de template
        form.current,
        'MIofBTMMgP2SmPi-c' // Substitua pela sua chave pública
      )
      .then(
        () => {
          alert('Mensagem enviada com sucesso!');
          form.current.reset(); // Limpa o formulário após o envio bem-sucedido
        },
        (error) => {
          console.error('Falha ao enviar a mensagem:', error.text);
        }
      );
  };

  return (
    <Section id="contact">
      <Title>Entre em Contato</Title>
      <Form ref={form} onSubmit={sendEmail}>
        <label htmlFor="name">Nome</label>
        <input type="text" name="name" id="name" placeholder="Seu nome" required />
        
        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" placeholder="Seu email" required />

        <label htmlFor="subject">Assunto</label>
        <input type="text" name="subject" id="subject" placeholder="Assunto" required />
        
        <label htmlFor="message">Mensagem</label>
        <textarea name="message" id="message" placeholder="Sua mensagem" rows="4" required />

        <button type="submit">Enviar</button>
        <WhatsAppButton href="https://wa.me/5511965070544?text=Ol%C3%A1%20Chaveiro%20Falc%C3%A3o!%20Gostaria%20de%20informações%20sobre%20chaves%20codificadas." target="_blank" rel="noopener noreferrer">
          Contato pelo WhatsApp
        </WhatsAppButton>
      </Form>
      
    </Section>
  );
};

export default ContactUs;
