import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const FooterWrapper = styled.footer`
  padding: 2rem;
  background-color: #282c34;
  color: white;
  text-align: center;
`;

const SocialLinks = styled.div`
  margin-bottom: 1rem;

  a {
    color: white;
    margin: 0 1rem;
    text-decoration: none;
    font-size: 1.5rem;
    
    &:hover {
      color: #25d366; /* cor ao passar o mouse */
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <SocialLinks>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </SocialLinks>
      <p>&copy; {new Date().getFullYear()} Chaveiro Falcão. Todos os direitos reservados.</p>
    </FooterWrapper>
  );
};

export default Footer;
