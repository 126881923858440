import React from 'react';
import styled from 'styled-components';
import '@fortawesome/fontawesome-free/css/all.min.css';  // Certifique-se de importar o CSS

const WhatsappButton = styled.a`
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 25px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #20b358;
  }

  i {
    margin-top: 15px; /* Alinha o ícone no centro verticalmente */
  }
`;

const FloatingWhatsappButton = () => {
  return (
    <WhatsappButton
      href="https://wa.me/5511965070544?text=Ol%C3%A1%20Chaveiro%20Falc%C3%A3o!%20Gostaria%20de%20informações%20sobre%20chaves%20codificadas."
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fab fa-whatsapp"></i> {/* Ícone correto do WhatsApp */}
    </WhatsappButton>
  );
};

export default FloatingWhatsappButton;
