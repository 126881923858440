import React, { useState } from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  background-color: #0a0a0a;
  color: white;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;

  img {
    max-height: 100px;
    width: auto;
    border-radius: 50%;
  }
`;

const Nav = styled.nav`
  ul {
    display: flex;
    list-style: none;

    @media (max-width: 768px) {
      display: ${(props) => (props.isOpen ? 'flex' : 'none')};
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      background-color: #0a0a0a;
      width: 100%;
      padding: 10px 0;
    }
  }

  ul li {
    margin: 0 15px;

    @media (max-width: 768px) {
      margin: 10px 0;
      text-align: center;
    }
  }

  ul li a {
    color: #fdfcfa;
    font-size: 1.1rem;
    transition: color 0.3s ease;
    text-decoration: none;

    @media (max-width: 768px) {
      color: white;
    }

    &:hover {
      color: #ffc107;
    }
  }
`;

const MenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Função para fechar o menu ao clicar em um item
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <HeaderWrapper>
      <Logo>
        <img src="/images/logo.png" alt="Logo" />
      </Logo>
      <MenuToggle onClick={toggleMenu}>
        {isOpen ? '✖' : '☰'}
      </MenuToggle>
      <Nav isOpen={isOpen}>
        <ul>
          <li><a href="#home" onClick={closeMenu}>Home</a></li>
          <li><a href="#projects" onClick={closeMenu}>Projetos</a></li>
          <li><a href="#services" onClick={closeMenu}>Serviços</a></li>
          <li><a href="#contact" onClick={closeMenu}>Contato</a></li>
        </ul>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;
