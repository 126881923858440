import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react'; 
import 'swiper/css';  // Importa o CSS básico do Swiper
import 'swiper/css/autoplay';  // Importa o CSS para autoplay
import { Autoplay } from 'swiper/modules'; // Importa o módulo Autoplay

const Section = styled.section`
  padding: 3rem 1rem;
  background-color: #9fa6af;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const ProjectItem = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProjectImage = styled.img`
  width: 100%; /* Ajusta a largura para 100% do container */
  max-height: 700px; /* Define uma altura máxima para as imagens */
  height: auto; /* A altura é ajustada automaticamente */
  object-fit: cover; /* Faz com que a imagem se ajuste sem distorcer, cortando se necessário */
  border-radius: 10px;
  margin-bottom: 1rem;
  /* Media query para dispositivos móveis */
  @media (max-width: 768px) {
    height: 200px; /* Ajusta a altura para telas menores */
    width: 100%;
  }
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: #666;
`;

const Projects = () => {
  const items = [
    { id: 1, title: "", description: "Chave codificada", image: "/images/carros.jpg" },
    { id: 2, title: "", description: "Cópia de chaves", image: "/images/carro.jpg" },
    { id: 3, title: "", description: "Cópia de chaves", image: "/images/ChaveiroFalcao_carro1.jpg" },
    { id: 4, title: "", description: "Cópia de chaves", image: "/images/ChaveiroFalcao_carro2.jpg" },
    { id: 5, title: "", description: "Cópia de chaves", image: "/images/ChaveiroFalcao_carro3.jpg" },
    { id: 6, title: "", description: "Cópia de chaves", image: "/images/copia-slot-presenca.jpg" },
    { id: 7, title: "", description: "Abertura de carros", image: "/images/abrindo-carro.jpg" },
    { id: 8, title: "", description: "Cópia de chaves", image: "/images/automotivo-chave-codificada.jpg" },
    { id: 9, title: "", description: "Cópia de chaves", image: "/images/land-rover.jpg" },
    { id: 10, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao1.jpg" },
    { id: 11, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao2.jpg" },
    { id: 12, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao3.jpg" },
    { id: 13, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao4.jpg" },
    { id: 14, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao5.jpg" },
    { id: 15, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao6.png" },
    { id: 16, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao7.jpg" },
    { id: 17, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao8.jpg" },
    { id: 18, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao9.jpg" },
    { id: 19, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao10.jpg" },
    { id: 20, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao11.jpg" },
    { id: 21, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao12.jpg" },
    { id: 22, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao13.jpg" },
    { id: 23, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao14.jpg" },
    { id: 24, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao15.jpg" },
    { id: 25, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao16.jpg" },
    { id: 26, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao17.jpg" },
    { id: 27, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao18.jpg" },
    { id: 28, title: "", description: "Cópia de chaves", image: "/images/IMG-chaveirofalcao19.jpg" }
  ];

  return (
    <Section id="projects">
      <Title>Nossos Projetos</Title>
      <Swiper
        spaceBetween={20}
        slidesPerView={1} // Mostra apenas 1 slide por vez
        autoplay={{
          delay: 3000, // 3 segundos entre cada slide
          disableOnInteraction: false, // Continua rodando mesmo após interação
        }}
        loop={true} // Loop infinito
        modules={[Autoplay]} // Inclui apenas o módulo de autoplay
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <ProjectItem>
              <ProjectImage src={item.image} alt={item.title} />
              <h3>{item.title}</h3>
              <ProjectDescription>{item.description}</ProjectDescription>
            </ProjectItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  );
};

export default Projects;
