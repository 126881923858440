import React from 'react';
import Header from './components/Header';
import Presentation from './components/Presentation';
import Projects from './components/Projects';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import GlobalStyle from './GlobalStyle';
import FloatingWhatsappButton from './components/FloatingWhatsappButton';

function App() {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Presentation />
      <Projects />
      <Services />
      <Testimonials />
      <ContactForm />
      <Footer />
      <FloatingWhatsappButton />
    </>
  );
}

export default App;
